#profile-box {
	position: relative;
}

#profile-edit {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
}

#profile-edit:hover {
	cursor: pointer;
}

#profile-picture {
	padding: 20px;
	width: 190px;
	height: 190px;
}
