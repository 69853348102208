html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
*,
*:before,
*:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}
html,
body,
#root {
	height: 100vh;
}
#root {
	/* push footer to bottom on low content pages */
	display: flex;
	flex-direction: column;
}

main {
	margin: 0 auto;
	flex: 1;
}

footer {
	flex-shrink: 0;
}
